.filters {
  :global {
    .p-menu.p-component {
      border: none;
      width: 100%;
      padding-top: 0;
    }

    .p-submenu-header {
      display: none;
    }

    li.p-menuitem {
      margin-left: -.5rem;
      margin-right: -.5rem;
    }

    a.p-menuitem-link {
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
}
