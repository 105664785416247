.eventsDashboard {
  :global {
    .p-panel-header {
      background-color: transparent;
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: -4px;
    }
  }
}

.eventsDashboardPanel {
  :global {
    .p-panel-title {
      width: 100%;
    }

    .p-panel-header {
      border-bottom-style: dashed;
      background-color: transparent;
    }

    .field.grid {
      margin-left: 0 !important;
    }

    .field.grid label {
      font-weight: bold;
      width: 150px;
    }
  }
}
