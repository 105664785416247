.floatHeader {
  position: absolute;
  writing-mode: vertical-rl;
  background-color: #ffffff;
  padding: 8px;
  top: 0;

  h2 {
    margin: 0;
    padding: 0;

    font-size: 1rem;
  }
}
