.eventsFavorites {
  :global {
    .p-panel-header {
      background-color: transparent;
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: -4px;
    }
  }
}
